.App {
  width: 1024px;
  height: 568px;
  background-image: url("./assets/MediaPlayerAssets_x2/bg_overlay_2x.png");
  background-size: cover;
  background-color: #1d0e29;
}

@media screen and (min-width: 2048px) {
  .App {
    width: 2048px;
    height: 1136px;
  }
}

body {
  margin: 0;
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Courier New", monospace;
}
