@font-face {
  font-family: marine;
  src: url("../../assets/Marine-Bold.woff");
  font-weight: bold;
}

.buttonContainer {
  background-image: url("../../assets/MediaPlayerAssets_x2/button_blue_2x.png");
  background-size: cover;

  width: 328px;
  height: 90px;
  cursor: pointer;
  position: relative;
}

@media screen and (min-width: 2048px) {
  .buttonContainer {
    width: 656px;
    height: 180px;
  }
}

.buttonContainer a {
  text-decoration: none;
}

.buttonInnerContainer {
  display: flex;
  flex-direction: row;
  z-index: 1;
  position: relative;
  top: 0px;
  left: 0px;
}

.buttonUnderlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 328px;
  height: 90px;
  cursor: pointer;
  text-decoration: none;
  z-index: 0;
}

@media screen and (min-width: 2048px) {
  .buttonContainer {
    width: 656px;
    height: 180px;
  }
}

.buttonContainer:hover {
  background-image: url("../../assets/MediaPlayerAssets_x2/button_highlight_2x.png"),
    url("../../assets/MediaPlayerAssets_x2/button_blue_2x.png");
  background-size: cover;
}

.buttonText {
  font-family: marine;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  max-width: 218px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 27px;
  margin-top: 3px;
}

@media screen and (min-width: 2048px) {
  .buttonText {
    font-size: 32px;

    max-width: 436px;
    line-height: 54px;
    margin-top: 6px;
  }
}

.buttonTitle {
  composes: buttonText;
  font-size: 30px;
  line-height: 30px;
  margin: 1.5px 0px -1.5px 0px;
}

@media screen and (min-width: 2048px) {
  .buttonTitle {
    composes: buttonText;
    font-size: 60px;
    line-height: 60px;
  }
}

.icon {
  width: 90px;
  height: 90px;
  margin-left: 10px;
  background-size: cover;
}

@media screen and (min-width: 2048px) {
  .icon {
    width: 180px;
    height: 180px;
    margin-left: 20px;
  }
}

.iconVinyl {
  composes: icon;
  background-image: url("../../assets/MediaPlayerAssets_x2/icon_vinyl_2x.png");
}

.iconFlower {
  composes: icon;
  background-image: url("../../assets/MediaPlayerAssets_x2/icon_flower_2x.png");
}

.iconSun {
  composes: icon;
  background-image: url("../../assets/MediaPlayerAssets_x2/icon_sun_2x.png");
}

.iconStar {
  composes: icon;
  background-image: url("../../assets/MediaPlayerAssets_x2/icon_star_2x.png");
}

.iconLeaf {
  composes: icon;
  background-image: url("../../assets/MediaPlayerAssets_x2/icon_leaf_2x.png");
}

.iconSnow {
  composes: icon;
  background-image: url("../../assets/MediaPlayerAssets_x2/icon_snow_2x.png");
}

.iconForevr {
  composes: icon;
  background-image: url("../../assets/MediaPlayerAssets_x2/icon_forevr_2x.png");
}

.iconHeart {
  composes: icon;
  background-image: url("../../assets/MediaPlayerAssets_x2/icon_heart_2x.png");
}

.iconQuestion {
  composes: icon;
  background-image: url("../../assets/MediaPlayerAssets_x2/icon_question_2x.png");
}

.iconPumpkin {
  composes: icon;
  background-image: url("../../assets/MediaPlayerAssets_x2/icon_pumpkin2x.png");
}