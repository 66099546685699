.bounds {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
}

.header {
  position: relative;
  height: 96px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7e75c5;
}

@media screen and (min-width: 2048px) {
  .header {
    height: 192px;
  }
}

.headerFont {
  font-size: 50px;
  font-weight: bold;
  font-family: marine;
}
@media screen and (min-width: 2048px) {
  .headerFont {
    font-size: 100px;
  }
}

.lockMessage {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: rgba(59, 59, 62, 0.8);
  height: 80%;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 2048px) {
  .lockMessage {
    font-size: 36px;
    line-height: 36px;
  }
}
