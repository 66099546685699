.menuContainer {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 20px;
  row-gap: 20px;
  padding: 0px 70px;
}
@media screen and (min-width: 2048px) {
  .menuContainer {
    column-gap: 40px;
    row-gap: 40px;
    padding: 0px 140px;
  }
}

.buttonContainer {
  display: inline-grid;
  color: white;
}

.ctaButtonContainer {
  composes: buttonContainer;
  grid-column: 1/3;
  display: flex;
  justify-content: center;
}
