.navArrowBase {
  width: 100px;
  height: 568px;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  top: 0px;
  background-color: transparent;
  border: none;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 5;
}

.rightArrow {
  composes: navArrowBase;
  right: 0px;
  background-image: url("../../assets/MediaPlayerAssets/arrow_right.png");
}
.rightArrow:hover {
  background-image: url("../../assets/MediaPlayerAssets/arrow_right_highlight.png"),
    url("../../assets/MediaPlayerAssets/arrow_right.png");
}
.leftArrow {
  composes: navArrowBase;
  left: 0;
  background-image: url("../../assets/MediaPlayerAssets/arrow_left.png");
}
.leftArrow:hover {
  background-image: url("../../assets/MediaPlayerAssets/arrow_left_highlight.png"),
    url("../../assets/MediaPlayerAssets/arrow_left.png");
}

@media screen and (min-width: 2048px) {
  .navArrowBase {
    width: 200px;
    height: 1136px;
    display: inline-block;
    cursor: pointer;
    position: absolute;
    top: 0px;
    background-color: transparent;
    border: none;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 5;
  }

  .rightArrow {
    composes: navArrowBase;
    right: 0px;
    background-image: url("../../assets/MediaPlayerAssets_x2/arrow_right_2x.png");
  }
  .rightArrow:hover {
    background-image: url("../../assets/MediaPlayerAssets_x2/arrow_right_highlight_2x.png"),
      url("../../assets/MediaPlayerAssets_x2/arrow_right_2x.png");
  }
  .leftArrow {
    composes: navArrowBase;
    left: 0;
    background-image: url("../../assets/MediaPlayerAssets_x2/arrow_left_2x.png");
  }
  .leftArrow:hover {
    background-image: url("../../assets/MediaPlayerAssets_x2/arrow_left_highlight_2x.png"),
      url("../../assets/MediaPlayerAssets_x2/arrow_left_2x.png");
  }
}
